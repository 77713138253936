import React from 'react';

class Tile extends React.Component {
  render() {
    return (
      <p></p>
    )
  }
}

export default Tile;