import './App.css';
import Main from "./frontend/main"

function App() {
  return (
    <Main />
  );
}

export default App;
